import React, { FC, Suspense, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, useHistory } from 'react-router-dom'

import { setChartsData } from '../../actions'
import { dropTraderProfile, resetProfileTradingHistory } from '../../actions/traderProfileActions'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { TokenId } from '../../model'
import { State } from '../../reducers'
import {
  HISTORY_ROUTE_TEMPLATE,
  LIQUIDITY_ROUTE_TEMPLATE,
  TOKEN_PROFILE_ROUTE_TEMPLATE,
  TRADER_PROFILE_TEMPLATE,
  TRADER_PROFILE_TRADE_TEMPLATE,
} from '../../routes'
import { loadChartsData } from '../../services/chartService'
import { useRouteChange } from '../ChatWidget/chatHooks'
import HeaderDesktop from '../Header/HeaderDesktop'
import OnboardingDesktop from '../Onboarding/OnboardingDesktop'
import ProtectedRoute from '../ProtectedRoute'
import DashboardDesktopContent from './DashboardDesktopContent'

const TraderProfile = React.lazy(
  () => import(/* webpackPrefetch: true */ '../TraderProfile/TraderProfile')
)

const TradingHistoryFull = React.lazy(
  () => import(/* webpackPrefetch: true */ '../TransactionHistory/TradingHistoryFull')
)

const TokenProfile = React.lazy(() => import(/* webpackPrefetch: true */ '../TokenProfile'))

const PoolActivityFull = React.lazy(
  () => import(/* webpackPrefetch: true */ '../PoolActivity/PoolActivityFull')
)

export const DashboardDesktop: FC = () => {
  const reduxDispatch = useDispatch()

  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const selectedCurrency = useSelector((state: State) => state.currency)
  const history = useHistory()

  const currentTokenId = currentToken?.id
  const getData = useCallback(
    async (selectedCurrency: string, currentTokenId: TokenId): Promise<void> => {
      const defaultQuantity = 9
      const allAMMsOnly = 'all'
      const chartData = await loadChartsData(
        selectedCurrency,
        currentTokenId,
        defaultQuantity,
        allAMMsOnly
      )
      if (chartData) {
        reduxDispatch(setChartsData(chartData))
      }
    },
    [reduxDispatch]
  )

  useComponentDidMount(() => {
    resetState()
  })

  useEffect(() => {
    if (currentTokenId && selectedCurrency) {
      getData(selectedCurrency, currentTokenId)
    }
  }, [currentTokenId, selectedCurrency, getData])

  const resetState = (): void => {
    reduxDispatch(dropTraderProfile({ pathname: undefined }))
    reduxDispatch(resetProfileTradingHistory())
  }

  useRouteChange(history)

  return (
    <>
      <div className="dashboard">
        <div className="dashboard__header">
          <HeaderDesktop />
        </div>
        <div className="dashboard__body">
          <Switch>
            <ProtectedRoute exact path={TOKEN_PROFILE_ROUTE_TEMPLATE}>
              <Suspense fallback={<div />}>
                <TokenProfile />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute
              exact={true}
              path={[TRADER_PROFILE_TEMPLATE, TRADER_PROFILE_TRADE_TEMPLATE]}>
              <Suspense fallback={<div />}>
                <TraderProfile onClose={resetState} />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute exact={true} path={[LIQUIDITY_ROUTE_TEMPLATE]}>
              <Suspense fallback={<div />}>
                <PoolActivityFull />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute exact={true} path={[HISTORY_ROUTE_TEMPLATE]}>
              <Suspense fallback={<div />}>
                <TradingHistoryFull />
              </Suspense>
            </ProtectedRoute>
            <DashboardDesktopContent isMarketTypeLP={currentToken?.marketType === 'lp'} />
          </Switch>
        </div>
      </div>
      <OnboardingDesktop />
    </>
  )
}
