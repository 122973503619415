import cn from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../reducers'
import MainPartDesktop from '../MainPart/MainPartDesktop'
import Sidebar from '../Sidebar/Sidebar'
import { SidebarType } from '../Sidebar/sidebarUtils'

export const DashboardDesktopContent: React.FC<{
  isMarketTypeLP: boolean
}> = ({ isMarketTypeLP }) => {
  const { isSidebarOpen } = useSelector((state: State) => state.ui)

  return (
    <div className="dashboard__content">
      <aside
        className={cn('dashboard__sidebar', 'dashboard__sidebar--left', {
          open: isSidebarOpen === 'liquidity',
          close: isSidebarOpen !== 'liquidity' && isSidebarOpen !== 'none',
        })}>
        <Sidebar type={SidebarType.liquidity} position="left" isMobile={false} />
      </aside>

      <main className={cn('dashboard__main', { close: isSidebarOpen !== 'none' })}>
        <MainPartDesktop />
      </main>

      {!isMarketTypeLP && (
        <aside
          className={cn('dashboard__sidebar', 'dashboard__sidebar--right', {
            open: isSidebarOpen === 'history',
            close: isSidebarOpen !== 'history' && isSidebarOpen !== 'none',
          })}>
          <Sidebar type={SidebarType.history} position="right" isMobile={false} />
        </aside>
      )}
    </div>
  )
}

export default DashboardDesktopContent
