import classNames from 'classnames'
import React, { FC } from 'react'

import { financialFormat } from '../helpers/priceAndAmountHelpers'
import { isTitleNaN, numberWithCommas } from '../utils'

export interface DeltaProps {
  value?: number
  size?: 'sm' | 'md' | 'lg'
  className?: string
  tooltip?: string
  showZero?: boolean
}

export const normalizeDelta = (value: number): string => {
  return value < 100
    ? financialFormat(value, { decimals: 2 })
    : financialFormat(value, { roundBigNumbersFrom: 'K' })
}

const Delta: FC<DeltaProps> = ({ value = 0, size, className, tooltip, showZero = false }) => {
  if (!value && !showZero) {
    return null
  }

  const absValue = Math.abs(value)

  const tooltipData = tooltip
    ? {
        'data-for': 'app-tooltip',
        'data-tip': `<div class='text'>${tooltip}</div>`,
      }
    : {}

  const displayValue = normalizeDelta(absValue)

  return (
    <span
      className={classNames('delta', size ? `delta--${size}` : '', className || '', {
        'delta--up': value > 0,
        'delta--down': value < 0,
        'delta--low': absValue && displayValue === '0',
      })}
      title={isTitleNaN(numberWithCommas(absValue))}
      {...tooltipData}>
      <span className="delta__value">{displayValue}%</span>
    </span>
  )
}

export default Delta
